<template>
  <van-popup
    :close-on-click-overlay="false"
    v-model="show"
    style="background-color: transparent;overflow-y: visible;"
    :overlay-style="overlayStyle"
  >
    <div class="loadingWrap">
      <van-loading v-if="show" color="#1989fa" size="45" />
    </div>
  </van-popup>
</template>

<script>
export default {
  name: "loading",
  data() {
    return {
      show: false,
      overlayStyle: {
        background: "rgba(0, 0, 0, 0.5)",
      },
    };
  },
};
</script>




