import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('../views/menu'),
    meta: {
      title: '菜单'
    }
  },
  {
    path: '/roomStateControl',
    name: 'roomStateControl',
    component: () => import('../views/roomStateControl'),
    meta: {
      title: '房态控制'
    }
  },
  {
    path: '/calendarRoomOrder',
    name: 'calendarRoomOrder',
    component: () => import('../views/calendarRoomOrder'),
    meta: {
      title: '日历房订单'
    }
  },
  {
    path: '/writeOffRecord',
    name: 'writeOffRecord',
    component: () => import('../views/writeOffRecord'),
    meta: {
      title: '核销记录'
    }
  },
  {
    path: '/writeOffDetail',
    name: 'writeOffDetail',
    component: () => import('../views/writeOffDetail'),
    meta: {
      title: '核销详情'
    }
  },
  {
    path: '/groupHotel/reply',
    name: 'groupHotelReply',
    component: () => import('@/views/groupHotel/reply'),
    meta: {
      title: '团房回复'
    }
  },
  {
    path: '/groupHotel/search',
    name: 'groupHotelSearch',
    component: () => import('@/views/groupHotel/search'),
    meta: {
      title: '酒店搜索'
    }
  },
  {
    path: '/groupHotel',
    name: 'groupHotel',
    component: () => import('@/views/groupHotel/index'),
    meta: {
      title: '团房申请单'
    }
  },
  {
    path: '/groupHotelForSaas',
    name: 'groupHotelForSaas',
    component: () => import('@/views/groupHotel/indexForSass'),
    meta: {
      title: '团房申请单'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'vebk-h5'
  next()
})

export default router
