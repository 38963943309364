import router from '@/router'
import get from 'lodash.get'

let _data
let _callback

// 页面交互信息缓存
const _layer = {}

const _get = () => {
  const res = _data
  // 跳转页面后可能需要获取上一个页面传过来的数据, 因此不能清空 callback
  // 否则返回时无法进行回调
  _data = undefined
  return res
}

const _getCallback = () => _callback

/**
 * 缓存页面交互信息
 * */
const _save = () => {
  const page = get(router, 'history.current.path')
  _layer[page] = { callback: _callback }
}

/**
 * 设置页面交互信息
 * */
const _set = (data, callback) => {
  // 缓存页面交互信息
  if (_callback) _save()

  _data = data
  _callback = callback && typeof callback === 'function' ? callback : undefined
}

/**
 * 触发交互回调
 * @param {*} data  回调内容
 * */
const _trigger = data => {
  const page = get(router, 'history.current.path')

  const method = get(_layer[page], 'callback') || _callback

  if (method) {
    method(data)
    _data = undefined
  } else {
    _data = data
  }

  delete _layer[page]

  _callback = undefined
}

export default {
  set: _set,
  save: _save,
  get: _get,
  getCallback: _getCallback,
  callback: _trigger
}
