import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import FastClick from 'fastclick'
import mixinGlobal from '@/mixin/global'
import './assets/style/reset.css'
import './assets/style/border.css'
import './assets/style/size.scss'
import './assets/style/space.scss'

import VConsole from 'vconsole'
if (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'local') {
  const vConsole = new VConsole()
}
import {
  Button, Popup, DropdownMenu, DropdownItem, Calendar, Switch, Icon, Tab, Tabs, List, PullRefresh, Toast, Stepper, Picker, Dialog, Field, Form, Cell, Empty, Loading
} from 'vant'
Vue.use(Button).use(Popup).use(DropdownMenu).use(DropdownItem).use(Calendar).use(Switch).use(Icon).use(Tab).use(Tabs).use(List).use(PullRefresh).use(Toast).use(Stepper).use(Picker).use(Dialog).use(Field).use(Form).use(Cell).use(Empty).use(Loading)

import { installLoading } from '@/tool/loading'
Vue.use(installLoading)

Vue.mixin(mixinGlobal)

if ('addEventListener' in document) {
  document.addEventListener('DOMContentLoaded', function() {
    FastClick.attach(document.body)
  }, false)
}

FastClick.prototype.focus = function(targetElement) {
  let length
  if (targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month') {
    length = targetElement.value.length
    targetElement.focus()
    targetElement.setSelectionRange(length, length)
  } else {
    targetElement.focus()
  }
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
