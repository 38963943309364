import pageInteraction from '@/tool/page-interaction'

export default {
  created() {
    this.$pageInteraction = pageInteraction
  },
  methods: {
    $push(name, query, props = {}) {
      const { pageData, pageCallback } = props

      // 设置页面交互内容
      pageInteraction.set(pageData, pageCallback)

      this.$router.push({ name, query })
    }
  }
}
