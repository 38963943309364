export function mergeArray(idArray, infoArray, idKey, infoKey = idKey) {
  for (const item of idArray) {
    Object.assign(item, infoArray.find((el) => item[idKey] == el[infoKey]))
  }
}

export function filterAmount(amount, unit = '￥') {
  return `${unit}${Number.parseFloat(amount).toFixed(2)}`
}
// 获取某个月份的天数
export function getDaysOfMonth(year, month) {
  var date = new Date(year, month, 0)
  var days = date.getDate()
  return days
}

// 获取接下来几天的日期、周几包含当日
const weekText = ['日', '一', '二', '三', '四', '五', '六']
export function getNextDate(count, time) {
  const startTime = time ? new Date(formatIOSTime(time)) : new Date()
  const date = parseInt(startTime.getDate()) // 当前是第几天
  const dateList = []
  for (let i = 0; i <= count; i++) {
    // 不重新赋值的话 每次setDate 之后 startTime 都会改变 是否有其他解决方案？
    const startTime = time ? new Date(time) : new Date()
    const newTime = new Date(startTime.setDate(date + i))
    // const month = newTime.getMonth() + 1 < 10 ? `0${newTime.getMonth() + 1}` : newTime.getMonth() + 1
    // const _date = newTime.getDate() < 10 ? `0${newTime.getDate()}` : newTime.getDate()
    dateList.push(formatDate(newTime))
  }
  return dateList
}

// 获取前几天 count<0 的日期、周几包含当日
export function getLastDate(count, time) {
  const startTime = time ? new Date(formatIOSTime(time)) : new Date()
  const dateList = []
  for (let i = count; i <= 0; i++) {
    const newTime = new Date(startTime.getTime() + i * 24 * 3600 * 1000)
    // const month = newTime.getMonth() + 1 < 10 ? `0${newTime.getMonth() + 1}` : newTime.getMonth() + 1
    // const _date = newTime.getDate() < 10 ? `0${newTime.getDate()}` : newTime.getDate()
    dateList.push(formatDate(newTime))
  }
  return dateList
}

// 格式化日期
export function formatDate(time = new Date()) {
  const newTime = time instanceof Date ? time : new Date(formatIOSTime(time))
  const year = newTime.getFullYear()
  const month = newTime.getMonth() + 1 < 10 ? `0${newTime.getMonth() + 1}` : newTime.getMonth() + 1
  const date = newTime.getDate() < 10 ? `0${newTime.getDate()}` : newTime.getDate()
  const weekday = weekText[newTime.getDay()]
  const value = `${year}/${month}/${date}`
  const timestamp = newTime.getTime()
  return {
    year, month, date, weekday, value, timestamp
  }
}

// 兼容ios时间格式ios:不支持2020-01-01，支持2020/01/01
export function formatIOSTime(iosTime) {
  iosTime = iosTime || ''
  return iosTime.replace(/-/g, '/')
}

/**
 * json对象转字符串参数连接
 * @param   {Object}  json  query 参数
 * */
export const json2Form = json => {
  const queryText = Object.keys(json)
    .map(e => `${encodeURIComponent(e)}=${encodeURIComponent(json[e])}`)
    .join('&')

  return queryText ? `?${queryText}` : queryText
}

/**
 * 判断是否函数
 * @param   {*}       data  检查内容
 * @return  {Boolean}
 * */
export const isFunction = data =>
  Object.prototype.toString.call(data) === '[object Function]'

/**
 * 判断内容是否为空(数字 0 !== 空)
 * @param   {*}       data  检查内容
 * @return  {Boolean}       是否为空
 * */
export const isEmpty = data => {
  if ([undefined, null].includes(data)) return true

  const type = Object.prototype.toString.call(data)
  switch (type) {
    case '[object String]':
      return !data
    case '[object Array]':
      return data.length <= 0
    case '[object Object]':
      return Object.keys(data).length <= 0
    case '[object Number]':
    default:
      return false
  }
}
